import React, { useState, useEffect } from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const BrandedTemplate = ({ pageContext }) => {
	const { iframe, title } = pageContext;
	const [iframeSrc, setIframeSrc] = useState(null);

	useEffect(() => {
		// Extract the src attribute from the provided iframe string.
		const match = iframe.match(/src="([^"]+)"/);
		if (match && match[1]) {
			setIframeSrc(match[1]);
		}
	}, [iframe]);

	return (
		<>
			{/* Prevent search engines from indexing this page */}
			<GatsbySeo noindex={true} />
			<Helmet>
				<title>{title}</title>
				{/* Prevent body scroll so only the iframe scrolls */}
				<style>{`body { overflow: hidden; }`}</style>
			</Helmet>
			{/* Container takes full viewport height with overflow hidden */}
			<div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
				{iframeSrc && (
					<iframe
						src={iframeSrc}
						title={title}
						style={{
							width: "100%",
							height: "100vh",
							border: "none", // removes the border for a cleaner look
							overflow: "auto", // allow scrolling inside the iframe
						}}
						allowFullScreen
					/>
				)}
			</div>
		</>
	);
};

export default BrandedTemplate;
